import '@olxid/olx-autos-landing-page/dist/Components/Section/Sections.scss';
import '@olxid/olx-autos-landing-page/dist/Components/Title/Title.scss';
import '@olxid/olx-autos-landing-page/dist/Components/NavigationHeader/NavigationHeader.scss';
import '@olxid/olx-autos-landing-page/dist/Components/TextWithIcon/TextWithIcon.scss';
import '@olxid/olx-autos-landing-page/dist/Components/CTA/CTA.scss';
import '@olxid/olx-autos-landing-page/dist/Widgets/NumberPlateInputWithLink/NumberPlateInputWithLink.scss';
import '@olxid/olx-autos-landing-page/dist/Components/StaticItem/StaticItem.scss';
import '@olxid/olx-autos-landing-page/dist/Widgets/UpagoSection/UpagoSection.scss';
import '@olxid/olx-autos-landing-page/dist/Widgets/StaticImageWithCTA/StaticImageWithCTA.scss';
import '@olxid/olx-autos-landing-page/dist/Widgets/ListContainer/ListContainer.scss';
import '@olxid/olx-autos-landing-page/dist/Components/Tabs/Tabs.scss';
import '@olxid/olx-autos-landing-page/dist/Widgets/TabCardContainer/TabCardContainer.scss';
import '@olxid/olx-autos-landing-page/dist/Widgets/BookAnAppointment/R1/BookAnAppointmentR1.scss';
import '@olxid/olx-autos-landing-page/dist/Widgets/BookAnAppointment/ConditionModal.scss';
import '@olxid/olx-autos-landing-page/dist/Widgets/BookAnAppointment/R3/BookAnAppointment.scss';
import '@olxid/olx-autos-landing-page/dist/Widgets/BookAnAppointment/R3/InputRadioButton.scss';
import '@olxid/olx-autos-landing-page/dist/Widgets/BookingConfirmedWidget/BookingConfirmedWidget.scss';
import '@olxid/olx-autos-landing-page/dist/Widgets/BookingConfirmedWidget/V2/BookingConfirmedWidget.scss';
import '@olxid/olx-autos-landing-page/dist/Widgets/BookingConfirmedWidget/V3/BookingConfirmedL2Q/BookingConfirmedL2QDesktop.scss';
import '@olxid/olx-autos-landing-page/dist/Widgets/BookingConfirmedWidget/V3/BookingConfirmedL2Q/BookingConfirmedL2QMobile.scss';
import '@olxid/olx-autos-landing-page/dist/Widgets/BrandsWidget/BrandsWidget.scss';
import '@olxid/olx-autos-landing-page/dist/Components/Bundle/Bundle.scss';
import '@olxid/olx-autos-landing-page/dist/Components/Bundle/SliderArrow/SliderArrow.scss';
import '@olxid/olx-autos-landing-page/dist/Components/EmptyCarComponent/EmptyCarComponent.scss';
import '@olxid/olx-autos-landing-page/dist/Components/ShimmerCarouselCard/ShimmerCrouselCard.scss';
import '@olxid/olx-autos-landing-page/dist/Components/SkeletonLoader/SkeletonLoader.scss';
import '@olxid/olx-autos-landing-page/dist/Components/PopularBrands/PopularBrands.scss';
import '@olxid/olx-autos-landing-page/dist/Components/RecentActivityCard/RecentActivityCard.scss';
import '@olxid/olx-autos-landing-page/dist/Components/RecentActivityCard/SliderArrow/SliderArrow.scss';
import '@olxid/olx-autos-landing-page/dist/Widgets/BuyEngagerWidget/BuyEngagerWidget.scss';
import '@olxid/olx-autos-landing-page/dist/Components/BrandSelection/BrandSelection.scss';
import '@olxid/olx-autos-landing-page/dist/Components/SubSection/SubSection.scss';
import '@olxid/olx-autos-landing-page/dist/Widgets/EqualWidthColumnStaticContent/EqualWidthColumnStaticContent.scss';
import '@olxid/olx-autos-landing-page/dist/Widgets/ScrollAbleList/ScrollAbleList.scss';
import '@olxid/olx-autos-landing-page/dist/Components/PriceEmi/PriceEmi.scss';
import '@olxid/olx-autos-landing-page/dist/Widgets/BuyerIntentWidget/BuyerIntentWidget.scss';
import '@olxid/olx-autos-landing-page/dist/Widgets/CXELoadingWidget/CXELoadingWidget.scss';
import '@olxid/olx-autos-landing-page/dist/Widgets/CfCarouselWidget/CfCarouselWidget.scss';
import '@olxid/olx-autos-landing-page/dist/Widgets/CfWidget/CfWidget.scss';
import '@olxid/olx-autos-landing-page/dist/Widgets/EqualSpacedStaticContent/EqualSpacedStaticContent.scss';
import '@olxid/olx-autos-landing-page/dist/Components/Accordian/Accordian.scss';
import '@olxid/olx-autos-landing-page/dist/Widgets/FAQWidget/FAQWidget.scss';
import '@olxid/olx-autos-landing-page/dist/Widgets/LeadGenForm/UserDetailsForm.scss';
import '@olxid/olx-autos-landing-page/dist/Widgets/LoanCalculator/CalculatorScreen/CalculatorScreen.scss';
import '@olxid/olx-autos-landing-page/dist/Widgets/LoanCalculator/EMIChoicesContainer/EMIChoicesContainer.scss';
import '@olxid/olx-autos-landing-page/dist/Widgets/LoanCalculator/LoanCalculator.scss';
import '@olxid/olx-autos-landing-page/dist/Components/Map/DefaultMap.scss';
import '@olxid/olx-autos-landing-page/dist/Widgets/Map/Map.scss';
import '@olxid/olx-autos-landing-page/dist/Widgets/MarkdownContentWithCTA/MarkdownContentWithCTA.scss';
import '@olxid/olx-autos-landing-page/dist/Widgets/NewsCarousel/NewsCarousel.scss';
import '@olxid/olx-autos-landing-page/dist/Widgets/OLXAutosHeader/OLXAutosHeader.scss';
import '@olxid/olx-autos-landing-page/dist/Widgets/PageLoading/PageLoadingWidget.scss';
import '@olxid/olx-autos-landing-page/dist/Widgets/PriceEstimation/PriceEstimation.scss';
import '@olxid/olx-autos-landing-page/dist/Widgets/ProgressBarLine/ProgressBarLine.scss';
import '@olxid/olx-autos-landing-page/dist/Widgets/ReviewCarousel/ReviewCarousel.scss';
import '@olxid/olx-autos-landing-page/dist/Widgets/SplitView/SplitView.scss';
import '@olxid/olx-autos-landing-page/dist/Widgets/StaticImageWithCTAAndDesc/StaticImageWithCTAAndDesc.scss';
import '@olxid/olx-autos-landing-page/dist/Widgets/StepProgress/StepProgress.scss';
import '@olxid/olx-autos-landing-page/dist/Components/SliderWrapper/SliderWrapper.scss';
import '@olxid/olx-autos-landing-page/dist/Widgets/TabsCarousel/TabsCarousel.scss';
import '@olxid/olx-autos-landing-page/dist/Widgets/ValuationError/ValuationError.scss';
import '@olxid/olx-autos-landing-page/dist/Widgets/VideoPlayer/VideoPlayer.scss';
/* eslint-disable max-lines */
/* eslint-disable react/prop-types */
/* eslint-disable react/no-did-update-set-state */
/* global window */

import React, { PureComponent, createRef } from 'react';
import { OLXAutosHomePage, fetchLayout, setCurrentTab, LayoutSelector, LayoutConstants, CampaingHelpers, ComponentDependencyProvider, locationHelpers, CampaignContants, REQUEST_ORIGIN, EvaluationConstants, UserSession, REQUEST_ORIGINS, LayoutHelper, BuyerIntentActions, TrackingHelper, SeoHelper } from 'olx-autos-landing-page';
import { connect } from 'react-redux';
import { compose } from 'redux';
import ReactObserver from 'Components/ReactObserver/ReactObserver';
import { injectIntl } from 'react-intl';
import classNames from 'classnames';
import withLocalStorage from 'HOCs/withLocalStorage/withLocalStorage';
import SITE_CODES from 'Constants/siteCodes';
import PropTypes from 'prop-types';
import withCookies from 'HOCs/withCookies/withCookies';
import { post } from 'Server';
import Page from 'Components/Page/Page';
import SearchHeader from 'Components/HeaderWrapper/Headers/SearchHeader/SearchHeader.APP_TARGET';
import Footer from 'Components/Footer/Footer';
import css from './OLXAutosShell.scss';
import { TRACKING_ORIGINS, CALLBACK_ASSISTED_SI_HOME } from './../../constants/layout';
import { getCMCCities } from 'Selectors/config';
import { isEmpty } from 'Helpers/objects';
import withDataFetching from 'HOCs/withDataFetching/withDataFetching';
import { getCXEQueryParams, getLayoutSource, shouldRequestViaBFF } from './../../helpers/layout';
import withTrack from 'HOCs/withTrack/withTrack';
import { setBrowsingModeSync, setOriginSync, setLandingURLSync, setAutosCurrentPageName } from 'Actions/track';
import OLXAutosWrapper from '../../OLXAutosWrapper';
import LoadingPlaceholder from './components/LoadingPlaceholder';
import withRouter from 'HOCs/withRouter';
import withLocation from 'HOCs/withLocation/withLocation';
import AsyncSeachOverlay from 'Components/OverlaySearch/AsyncOverlaySearch';
import AsyncItemsCarousel from './../../components/ItemsCarousel/AsyncItemsCarousel';
import { Helmet } from 'react-helmet-async';
import { getItem as getSessionStorageItem } from 'Helpers/sessionStorage';
import { getSelectedLocationSelector } from './../../helpers/locationHelpers';
import ValuationFlow from 'Autos/ValuationFlow/ValuationFlow';
import { IS_DESKTOP } from 'Constants/device.APP_TARGET';
import { userIsLogged } from 'Selectors/user';
import { withConfig } from 'HOCs/withConfig/withConfig';
import CookieManager from 'Helpers/cookies';
import Tabs from '../../components/Tabs/Tabs';
import { ConfigContext } from 'Components/ConfigProvider/ConfigContext';
import { OLXAUTOS, HP_OLX_TRACKING, VIEW_LISTINGS, VIEW_LISTINGS_RESULTS_SUMMARY, CAR_COMPARE_FLOW_STEP, ADPV_EVENTS } from 'Constants/tracking';
import { toggleShowAllCTA, fetchFormFields, fetchMakeValues, fetchCarDetails, getDataValues } from 'Autos/ValuationFlow/actions';
import UserDetailsFormAsync from 'Components/UserDetailsForm/UserDetailsFormAsync';
import { EXPONEA_EVENT_TYPES, EXPONEA_USER_FLOW } from 'Constants/exponeaLeads';
import AsyncNumberPlate from 'Autos/ValuationFlow/NumberPlate/AsyncNumberPlate.jsx';
import { UserDetailsFormWidget } from '../../../components/UserDetailsForm';
import StickyWhatsappButton, { STICKY_WHATSAPP_BUTTON_EXPERIMENT } from 'Autos/components/StickyWhatsappButton/StickyWhatsappButton';
import AsyncCarAmountSlider from '../../components/CarAmountSlider/AsyncCarAmountSlider';
import AsyncItemCard from '../../../components/Listing/listingMXComponents/ItemCard/AsyncItemCard';
import VasSheetAsync from 'Components/VasSheet/VasSheetAsync';
import AsyncEmptyCenter from 'Autos/components/EmptyCenter/AsyncEmptyCenter';
import withTags from 'HOCs/withTags/withTags';
import { routerParamsToSeoQuery } from 'Helpers/listing';
import withConsumerFinanceHook from '../../../pages/Item/adpvMXComponents/CFWidgetLegacy/withConsumerFinanceHook';
import AsyncUserDataForm from '../../components/UserDataForm/AsyncUserDataForm';
import AsyncThankYouWidget from '../../../components/ThankYouWidget/AsyncThankYouWidget';
import TnCCheckbox from 'Components/ExponeaLeadUI/TermsAndConditionsCheckbox/TermsAndConditionsCheckboxAsync';
import { INSPECTION_INFO_STATUS, LEAD_GEN_SOURCES } from 'Constants/items';
import BottomPopularSearchesMobile from 'Components/Listing/components/BottomSection/BottomPopularSearches.mobile';
import { getFilterAbundanceMetadata } from 'Actions/filtersAbundance';
import { fetchFiltersByCategoryId } from 'Actions/filters';
import { AUTOS_SELL_PAGE_NAME } from 'Constants/sellLandingPage';
import { B2C_DESKTOP_LAYOUT_NAME, B2C_DESKTOP_NON_CMC_LAYOUT_NAME, B2C_MOBILE_LAYOUT_NAME, B2C_MOBILE_NON_CMC_LAYOUT_NAME, C2B_DESKTOP_LAYOUT_NAME, C2B_MOBILE_LAYOUT_NAME, INSPECTION_DESKTOP_LAYOUT_NAME, INSPECTION_MOBILE_LAYOUT_NAME } from 'Constants/carousellLayoutName';
import CFWidget from '../../../pages/Item/adpvMXComponents/CFWidgetModule/CFWidgetAsync';
import { withMultipleExperiments } from 'LaquesisEOL/withMultipleExperiments';
import { removeSiLocation, setSiLocation } from 'Actions/selectLocation';
import { verifyRedirectionParameters } from 'Helpers/track';
import {
    API_PATH,
    PANAMERA_CLIENT,
    FIELD_CONSTANTS,
    FORM_FIELD_API_VERSION_L2Q
} from 'Autos/ValuationFlow/constants';
import { fetchDisclaimer } from 'Autos/BookAppointmentV2/actions';
import { TAB_NAME, CLEARER_LISTING_PAGE_ENTRY_POINT_EXP } from 'Constants/buyLandingPage';
import AppInstallation from '../../components/AppInstallation/AppInstallation';
import { withSchema } from 'HOCs/withSchema/withSchema';
import { OPC_EXP_QUOTE_PAGE, WIDGET_OMIT_QUOTE_PAGE, SINGLE_PRICE_QUOTE_EXP, MULTI_PRICE_WIDGET_OMIT, SINGLE_PRICE_WIDGET_OMIT } from 'Constants/sellCarO2O';
import { getQueryRedirectionParams } from 'Selectors/olxAutos';
import SocialMetaTags from 'Components/SEO/SocialMetaTags';
import { convertToAbsoluteImageURI } from 'Helpers/images';
import { PNG_EXT, SEO_OG_IMAGE_WIDTH } from 'Constants/images';
import Auth from '../../../Auth';
import { manipulateCarData } from 'Autos/ValuationFlow/helpers';
import { getAPIPathName } from 'Autos/helpers/helpers';
import TradeInFormAsync from 'Components/CrossSell/TradeInForm/TradeInFormAsync';
import getHeaderHeight from './autosHeaderMapping.APP_TARGET';
import SliderBanner from './components/SliderBanner';

// eslint-disable-next-line no-new
new OLXAutosWrapper();
const ComponentDependency = {
    SearchOverlay: AsyncSeachOverlay,
    ItemsCarousel: AsyncItemsCarousel,
    ValuationFlow,
    ConfigContext,
    NumberPlate: AsyncNumberPlate,
    UserDetailsFormWidget,
    ItemCard: AsyncItemCard,
    VasSheetAsync,
    CFWidget,
    EmptyCenter: AsyncEmptyCenter,
    CarAmountSlider: AsyncCarAmountSlider,
    withConsumerFinanceHook,
    UserDataForm: AsyncUserDataForm,
    ThankYouWidget: AsyncThankYouWidget,
    ReactObserver,
    TnCCheckbox
};

export class OLXAutosShell extends PureComponent {
    static propTypes = {
        layout: PropTypes.object,
        track: PropTypes.func,
        locationId: PropTypes.string,
        setBrowsingMode: PropTypes.func,
        trackerOrigins: PropTypes.object,
        isLoading: PropTypes.bool,
        isError: PropTypes.bool,
        location: PropTypes.shape({
            query: PropTypes.object,
            pathname: PropTypes.string
        }),
        setOrigin: PropTypes.func,
        requestOrigin: PropTypes.string,
        selectedLocation: PropTypes.object,
        userIsLogged: PropTypes.bool,
        marketConfig: PropTypes.shape({
            get: PropTypes.func.isRequired
        }),
        landingURLSearch: PropTypes.string,
        tabName: PropTypes.string,
        setCurrentTab: PropTypes.func.isRequired,
        toggleShowAllCTA: PropTypes.func,
        trackingOrigin: PropTypes.string,
        intl: PropTypes.shape({
            formatMessage: PropTypes.func.isRequired
        }).isRequired,
        footer: PropTypes.array,
        isPaidCampaign: PropTypes.bool,
        cxeBundle: PropTypes.object,
        buyerIntentEvalState: PropTypes.string,
        updateBuyerIntentEvalState: PropTypes.func,
        _removeSiLocation: PropTypes.func.isRequired,
        _setSiLocation: PropTypes.func.isRequired,
        siLocation: PropTypes.object.isRequired,
        getLeadId: PropTypes.func,
        localStorage: PropTypes.object,
        eligibleRegion: PropTypes.array,
        fetchDisclaimer: PropTypes.func,
        disclaimer: PropTypes.object,
        experiments: PropTypes.object,
        params: PropTypes.shape({
            seoTheme: PropTypes.string
        }),
        h1: PropTypes.string,
        seoTitle: PropTypes.string,
        seoDescription: PropTypes.string,
        featureFlag: PropTypes.string
    }

    static defaultProps = {
        setBrowsingMode: () => { },
        setOrigin: () => { },
        isPaidCampaign: false,
        buyerIntentEvalState: '',
        route: {}
    }
    constructor(props) {
        super(props);
        this.siteCode = props.marketConfig.get('siteCode');
        const widgetsToOmit = this.checkWidgetToOmit();

        this.state = {
            omittedCxeSection: [],
            omittedCxeWidget: widgetsToOmit,   // because we have to show the store inspection widget by default if none is available
            /* Patch: isTrackingCalledFirstTime is used to called tracking only one time under componentdidupdate. Should be fixed with SSR*/
            isTrackingCalledFirstTime: false
        };
        this.olxAutosConfig = props.marketConfig.get('olxAutos');
        const { olxAutosConfig } = this;

        this.siteCode = props.marketConfig.get('siteCode');
        this.isMxCl = olxAutosConfig && olxAutosConfig.isMXCL || false;
        this.SEOContent = olxAutosConfig && olxAutosConfig.SEOContent;
        this.onTabClick = this.onTabClick.bind(this);
        this.exchangeOverlayHash = props.marketConfig.get('leads', 'exchangeOverlayHash');
        this.headerRef = createRef();
        this.subHeaderRef = createRef();
        this.headerLogoRef = createRef();
        this.impressions = {};
        this.addImpressions = this.addImpressions.bind(this);
        this.seller_state = CookieManager.readCookie(EvaluationConstants.SELLER_STATE_COOKIE);
        this.isHandleSeoLink = props.marketConfig.get('olxAutos', 'isHandleSeoLink');
        this.isOlxAutosLandingPage = true;
    }

    componentDidMount() {
        this.startTime = Date.now();

        const { TAB_NAME, EVALUATION_STATES } = EvaluationConstants;
        const { trackerOrigins, setBrowsingMode, location, requestOrigin, selectedLocationId, tabName, marketConfig } = this.props;

        setBrowsingMode(trackerOrigins.OLX_AUTOS_HOME);
        CampaingHelpers.setSessionUtmParams(location, marketConfig?.get());
        const channel = CampaingHelpers.getChannelOfAcquisition(this.props.location.query, marketConfig?.get());
        const { CAMPAIGN_TYPE: { PAID }} = CampaignContants;
        const categoryId = this.olxAutosConfig && this.olxAutosConfig.carCategory;
        const categoryExtendedFooter = this.olxAutosConfig && this.olxAutosConfig.categoryExtendedFooter;

        window.addEventListener('beforeunload', this.handleValuationRouting);
        const { landingURLSearch } = this.props;

        if (!landingURLSearch && location && location.search) {
            this.props.setURL(location.search); // SAVING LANDING URL FOR PAID TRACK WHICH CAN BE TRACKED
        }

        if (channel && channel.type === PAID) {
            this.props.setOrigin(this.props.trackerOrigins.PAID_DIRECT);
        }
        else if (!requestOrigin || (this.isMxCl && requestOrigin === REQUEST_ORIGINS.EVALUATION_FORM)) {
            this.props.setOrigin(this.props.trackerOrigins.ORGANIC_DIRECT);
        }
        if (categoryExtendedFooter && tabName === TAB_NAME.BUY && !IS_DESKTOP) {
            const isAutosPlatform = marketConfig.get('olxAutos', 'isMXCL');

            this.props.fetchFiltersMetadata({ location, categoryId, locationId: selectedLocationId });
            this.props.fetchFiltersByCategoryId(isAutosPlatform, categoryId);
        }
        this.props.updateBuyerIntentEvalState(EVALUATION_STATES.INITIAL);
        this.props.fetchDisclaimer();
    }

    componentDidUpdate(prevProps) {
        const isLocationIdChanged = prevProps.locationId !== this.props.locationId;

        this.seller_state = CookieManager.readCookie(EvaluationConstants.SELLER_STATE_COOKIE);
        if (prevProps.location.pathname !== this.props.location.pathname || isLocationIdChanged) {
            if (prevProps.tabName !== this.props.tabName || isLocationIdChanged) {
                if (prevProps.tabName) {
                    this.props.fetchCXELayout(this.props.tabName);
                }
                else {
                    this.handleEmptyTabsInSession();
                }
            }
        }
        if (prevProps.buyerIntentEvalState && prevProps.buyerIntentEvalState !== this.props.buyerIntentEvalState) {
            this.props.fetchCXELayout();
        }

        /* Patch: It is used to called tracking only one time. Should be fixed with SSR */
        if (!this.state.isTrackingCalledFirstTime) {
            const { trackingOrigin, location: { query = {}}, params, marketConfig } = this.props;

            const urlWithRedirectionParam = verifyRedirectionParameters(query);
            const state = UserSession.getUserEvaluationState();
            let trackingParams = {
                reason: state,
                origin: trackingOrigin === TRACKING_ORIGINS.HP_DIRECT ? TRACKING_ORIGINS.ORGANIC_DIRECT : trackingOrigin,
                select_from: this.props.tabName.toLowerCase(),
                ...LayoutHelper.getAcquisitionChannelTrackingValues(),
                ...TrackingHelper.getThematicParams(params, marketConfig?.get()),
                ...getQueryRedirectionParams(this.props.landingURLSearch, this.props.location)
            };

            if (urlWithRedirectionParam) {
                trackingParams = {
                    ... trackingParams,
                    ...urlWithRedirectionParam
                };
            }

            this.props.track(OLXAUTOS.LANDING_PAGE_OPEN, trackingParams);
            this.setIsTrackingCalledFirstTime();
        }

        if (this.isMxCl && prevProps.tabName !== this.props.tabName && prevProps.tabName === EvaluationConstants.TAB_NAME.BUY) {
            this.sendListingEvents();
        }

        if (this.isMxCl && prevProps.tabName !== this.props.tabName && this.props.tabName === EvaluationConstants.TAB_NAME.BUY) {
            this.startTime = Date.now();
        }

        if (this.isMxCl && this.props.location.hash !== prevProps.location.hash && this.props.location.hash === this.exchangeOverlayHash) {
            this.props.track(ADPV_EVENTS.LEAD_FORM_OPEN, {
                chosen_option: LEAD_GEN_SOURCES.EXCHANGE_CAR,
                select_from: this.props.tabName.toLowerCase()
            });
        }
    }

    componentWillUnmount() {
        const { TAB_NAME } = EvaluationConstants;
        const { tabName } = this.props;

        this.props.setCurrentTab(TAB_NAME.INITIAL, false);

        if ((this.isMxCl && tabName === TAB_NAME.BUY) || !this.isMxCl) {
            this.sendListingEvents();
        }
    }

    checkWidgetToOmit = () => {
        const widgetsToOmit = [];

        // BASED ON LAQ EXP WE NEED TO SHOW HIDE CXE WIDGET, AS CXE WIDGET IS PART OF LAYOUT, WE DON'T WANT TO CREATE MULTIPLE LAYOUTS
        // HENCE UTILIZING THIS FEATURE, AS EXP WILL BE SHORT TERM AND LATER THIS CAN BE REMOVED
        // ROI - LESS CXE MAINTENANCE
        if (this.props.experiments[OPC_EXP_QUOTE_PAGE[this.siteCode]] !== 'b') {
            widgetsToOmit.push(WIDGET_OMIT_QUOTE_PAGE);
        }

        switch (this.siteCode) {
            case SITE_CODES.OLX_MX:
                if (this.props.experiments[SINGLE_PRICE_QUOTE_EXP[this.siteCode]] !== 'b') {
                    widgetsToOmit.push(...SINGLE_PRICE_WIDGET_OMIT[this.siteCode]);
                    break;
                }

                widgetsToOmit.push(...MULTI_PRICE_WIDGET_OMIT[this.siteCode]);

                break;
            default:
                break;
        }

        return widgetsToOmit;
    }

    getItemInfo = item => {
        const { inspection_info = {}, monetizationInfo, 'package': appliedPackage } = item;
        const isInspected = inspection_info && inspection_info.inspection_id && (!inspection_info.consent || inspection_info.consent === INSPECTION_INFO_STATUS.APPROVED);
        const isFeatured = !!((appliedPackage && (appliedPackage.id || appliedPackage.name)) || (monetizationInfo && !isEmpty(monetizationInfo) && monetizationInfo.currentProduct));

        return {
            isInspected,
            isFeatured
        };
    };

    handleValuationRouting =() => {
        const { EVALUATION_STATES: { VALUATION_DONE, VALUATION_ERROR }} = EvaluationConstants;
        const sellEvalCookie = CookieManager.readCookie(EvaluationConstants.SELLER_STATE_COOKIE);

        if ((sellEvalCookie === VALUATION_DONE || sellEvalCookie === VALUATION_ERROR) && this.siteCode === SITE_CODES.OLX_TR) {
            UserSession.setUserEvaluationState(EvaluationConstants.EVALUATION_STATES.INITIAL);
        }
    }

    sendListingEvents = () => {
        const el = {};
        let inspectedAdsCount = 0;
        let resultCount = 0;
        let featuredAdsCount = 0;

        const { trackingOrigin, browsingMode } = this.props;
        const { collections, elements } = this.props.cxeBundle;

        const impressions = Object.keys(this.impressions).filter(key => (this.impressions[key] && (this.impressions[key].isEventFired === false)));
        const impressionsV2 = impressions.map(key => this.impressions[key].id);
        const resultCountImpressionCount = impressions.length || 0;
        const inspectedAdsImpressionCount = impressions.filter(key => this.impressions[key].isInspected).length || 0;
        const featuredAdsImpressionCount = impressions.filter(key => this.impressions[key].isFeatured).length || 0;

        for (const id in elements) {
            el[id] = this.getItemInfo(elements[id]);
        }

        Object.keys(collections).forEach(url => {
            const adsList = collections[url].items || [];

            resultCount += adsList.length;
            adsList.forEach(id => {
                if (el[id].isInspected) {
                    inspectedAdsCount += 1;
                }
                else if (el[id].isFeatured) {
                    featuredAdsCount += 1;
                }
            });
        });

        const commonTrackingInfo = {
            origin: trackingOrigin,
            browsing_mode: browsingMode
        };

        this.props.track(VIEW_LISTINGS, {
            ...commonTrackingInfo,
            impressions: impressionsV2,
            ...LayoutHelper.getAcquisitionChannelTrackingValues()
        });

        this.props.track(VIEW_LISTINGS_RESULTS_SUMMARY, {
            ...commonTrackingInfo,
            inspected_ad_count: inspectedAdsCount,
            inspected_ad_count_impression: inspectedAdsImpressionCount,
            fa_ad_count: featuredAdsCount,
            fa_ad_count_impression: featuredAdsImpressionCount,
            result_count: resultCount,
            result_count_impression: resultCountImpressionCount,
            visual_applied: 'grid',
            time_spent: Math.floor((Date.now() - this.startTime) / 1000), // eslint-disable-line no-magic-numbers
            ...LayoutHelper.getAcquisitionChannelTrackingValues()
        });

        impressions.forEach(key => {
            this.impressions[key].isEventFired = true;
        });

        this.startTime = 0;
    }

    setIsTrackingCalledFirstTime = () => {
        this.setState({ isTrackingCalledFirstTime: true });
    }

    addImpressions(itemViewed) {
        const { id, isFeatured, spell_id = 0, isInspected } = itemViewed;

        if (!(id in this.impressions)) {
            this.impressions[id] = {
                id: `${isFeatured ? 'T' : 'O'}${spell_id}:${id}`,
                isEventFired: false,
                isInspected,
                isFeatured
            };
        }
    }

    trackWrapper = (eventName, trackingInfo) => {
        const extraParams = {
            gps_on_off: this.props.isGpsEnabled,
            ...getSelectedLocationSelector(this.props.selectedLocation)
        };
        const finalParams = { ...extraParams, ...trackingInfo };

        this.props.track(eventName, finalParams);
    }

     transformDesktop =originalLayout => {
         // Destructure the originalLayout object
         const { sections, ...rest } = originalLayout;

         const namesToRemove = ['Homepage-banner-mobile', 'Homepage-banner-desktop', 'desktop-banner-section-buy-tab', 'mobile-banner-buy-tab-section', 'desktop-banner-section', 'mobile-banner-section'];

         const filteredSections = sections.filter(section => !namesToRemove.includes(section.name));
         // Remove the first item from the sections array
         const updatedSections = filteredSections;

         // Create the new layout object with the updated sections
         const transformedLayout = {
             ...rest,
             sections: updatedSections
         };

         return transformedLayout;
     };

     getImageItems =originalLayout => {
         // Destructure the originalLayout object
         const { sections } = originalLayout;

         // const namesToGet = ['Homepage-banner-mobile', 'Homepage-banner-desktop', 'desktop-banner-section-buy-tab', 'mobile-banner-buy-tab-section', 'desktop-banner-section', 'mobile-banner-section'];
         const filteredSections = sections[0];

         return filteredSections && filteredSections.widgets[0].data.items && filteredSections.widgets[0].data.items.length > 0 ? filteredSections.widgets[0].data.items : [];
     }

     renderArrowAsset = (layout, previous, next) => {
         const { sections } = layout;

         const bannerSection = sections[0];

         if (bannerSection.widgets[0].data.items.length > 1) {
             const GetAssets = bannerSection.widgets[0].data?.assets && bannerSection.widgets[0].data?.assets[0];

             return GetAssets?.items.map(arrow => <span data-aut-id={ arrow.type === 'leftArrow' ? 'slider-arrow-previous' : 'slider-arrow-next' } dangerouslySetInnerHTML={ { __html: arrow.element } } style={ { ...GetAssets.style, ...arrow.style } } onClick={ arrow.type === 'leftArrow' ? previous : next } />);
         }

         return <></>;
     }

     getFileRestrictions = originalLayout => {
         // Destructure the originalLayout object
         const { sections } = originalLayout;

         // const namesToGet = ['Homepage-banner-mobile', 'Homepage-banner-desktop', 'desktop-banner-section-buy-tab', 'mobile-banner-buy-tab-section', 'desktop-banner-section', 'mobile-banner-section'];
         const filteredSections = sections[0];

         return filteredSections && filteredSections.widgets[0].data ? filteredSections.widgets[0].data.fileRestrictions : { height: 0, width: 0 };
     }

    renderH1Content = (layout = {}, h1TextContent) => {
        const titleFromLayoutSection = (layout?.sections ?? []).find(({ title }) => title?.heading === 'h1' && !!title?.text.length);

        // return null if H1 is defined in the Layout (avoiding duplication)
        if (titleFromLayoutSection) {
            return null;
        }

        const h1FromTagsRequestORConfig = this.props.h1 || h1TextContent;

        if (h1FromTagsRequestORConfig) {
            return (
                <h1 className={ css.h1 }>{ h1FromTagsRequestORConfig }</h1>
            );
        }

        return null;
    }

    getSEOTitle = defaultSEOTitle => {
        if (this.props.seoTitle?.length) {
            return this.props.seoTitle;
        }
        return defaultSEOTitle;
    }

    getSEODescription = defaultSEODescription => {
        if (this.props.seoDescription?.length) {
            return this.props.seoDescription;
        }
        return defaultSEODescription;
    }

    renderSEOContent = (layout = {}) => {
        const seoContent = this.SEOContent;

        if (!seoContent) {
            return null;
        }
        const { location, marketConfig, route } = this.props;
        const canonicalPath = route.path || location.pathname;
        const socialMetaTagsEnabled = marketConfig.get('socialMeta').enabled;
        const staticAssetUrl = marketConfig.get('staticAssets');
        let parsedImageUrl = '';

        if (socialMetaTagsEnabled) {
            const { widgets: [{ data = {}}] = [{}] } = layout.sections && layout.sections[0] || {};
            const { image: { url = '' } = {}} = data;

            const imageUrl = url.includes('$width$') ? url.replace('$width$', SEO_OG_IMAGE_WIDTH) : url;

            parsedImageUrl = convertToAbsoluteImageURI(imageUrl, PNG_EXT, staticAssetUrl);
        }
        let seoCont = {};
        let canonicalUrl = [];

        if (seoContent.sell) {
            seoCont = location.pathname && location.pathname.includes(this.olxAutosConfig?.urlKeywords?.sell || 'sell')
                ? (seoContent.sell || {})
                : (seoContent.buy || {});
        }
        else {
            seoCont = seoContent;
        }

        if (seoContent.canonicalMapping && (canonicalPath in seoContent.canonicalMapping)) {
            canonicalUrl = [
                { rel: 'canonical', href: this.props.marketConfig.get('host') + seoContent.canonicalMapping[canonicalPath] }
            ];
        }
        else {
            canonicalUrl = [
                { rel: 'canonical', href: this.props.marketConfig.get('host') + canonicalPath }
            ];
        }
        const seoConfigH1 = seoCont?.h1 || seoContent?.h1;
        const seoTitle = this.getSEOTitle(seoCont?.title || seoContent?.title);
        const seoDescription = this.getSEODescription(seoCont?.meta || seoContent?.meta);

        return (
            <>
                { this.renderH1Content(this.transformDesktop(layout), seoConfigH1) }
                { canonicalUrl && <Helmet link={ canonicalUrl } /> }
                { socialMetaTagsEnabled && <SocialMetaTags
                    title={ seoTitle }
                    description={ seoDescription }
                    image={ parsedImageUrl }
                /> }
                <Helmet>
                    { !!seoDescription && <meta name="description" content={ seoDescription } /> }
                    { !!seoTitle && <title>{ seoTitle }</title> }
                </Helmet>
            </>
        );
    }

    getPageHeader({ isVisible = false, headerProps = {}}, tabName) {
        if (isVisible) {
            return (<SearchHeader
                headerLogoRef={ this.headerLogoRef }
                headerRef={ this.headerRef }
                isPaidCampaign={ this.props.isPaidCampaign }
                seller_state={ this.seller_state }
                flowStep={ tabName === TAB_NAME.BUY && CAR_COMPARE_FLOW_STEP.LANDING_PAGE }
                { ...headerProps }
            />);
        }
        return null;
    }

    onTabClick({ key }) {
        const { tabName, trackingOrigin, marketConfig } = this.props;
        const tabsData = marketConfig.get('olxAutos', 'tabsData') || [];
        const selectedTabData = tabsData.find(tabData => tabData.key === key);

        if (key !== tabName && selectedTabData) {
            const { urlKey } = selectedTabData;
            const trackingInfo = {
                origin: trackingOrigin,
                acquisition_channel: getSessionStorageItem(CampaignContants.UTM_PARAMS_KEY) || '',
                chosen_option: key.toLowerCase(),
                select_from: tabName.toLowerCase(),
                flow_type: this.getTabEventFlowType(tabName)
            };

            this.props.track('hp_tab_clicked', trackingInfo);
            this.props.router.push(`${marketConfig.get('landingPageRoutes', urlKey)}`);
        }
    }

    getTabEventFlowType(tabName) {
        switch (tabName) {
            case TAB_NAME.BUY:
                return 'buy_landing';
            case TAB_NAME.SELL:
                return 'sell_landing';
            case TAB_NAME.FINANCE:
                return 'cf_landing';
            default:
                return '';
        }
    }

    handleEmptyTabsInSession() {
        if (UserSession.getTabName && !UserSession.getTabName()) {
            UserSession.setTabName((this.props.tabName));
        }
    }

    getSubheader({ isVisible = false }) {
        const { marketConfig, tabName } = this.props;
        const tabsData = marketConfig.get('olxAutos', 'tabsData') || [];
        const showSubheaderTabs = this.olxAutosConfig?.showSubheaderTabs;
        const TabsComponent = Tabs;

        if (isVisible && !!tabsData) {
            return (
                <div ref={ this.subHeaderRef }
                    className={ classNames(
                        css.subheader,
                        {
                            [css.desktop]: IS_DESKTOP,
                            [css.mobile]: !IS_DESKTOP && (this.isMxCl || showSubheaderTabs),
                            [css.tabWithoutSearch]: !IS_DESKTOP && showSubheaderTabs
                        }
                    ) }>
                    <TabsComponent tabName={ tabName }
                        tabsData={ tabsData }
                        onTabClick={ this.onTabClick }
                        header={ this.headerRef.current }
                        subHeader={ this.subHeaderRef.current }
                        headerLogo={ this.headerLogoRef.current }
                        isMxCl={ this.isMxCl }
                    />
                </div>);
        }
        return null;
    }

    getFooterPage({ isVisible = false, footerProps: { appendSections, ...footerProps } = {}} = {}) {
        const { SELLER_STATE_COOKIE, EVALUATION_STATES } = EvaluationConstants;
        const { marketConfig } = this.props;

        const trackProps = {
            reason: CookieManager.readCookie(SELLER_STATE_COOKIE) || EVALUATION_STATES.INITIAL,
            acquisition_channel: CampaingHelpers.getChannelOfAcquisition(this.props.location.query, marketConfig?.get())
        };
        const categoryId = this.olxAutosConfig && this.olxAutosConfig.carCategory;

        if (isVisible) {
            return (<Footer
                trackProps={ trackProps }
                sections={ appendSections ? this.props.footer : undefined }
                type={ 'landingPage' }
                categoryId={ categoryId }
                locationId={ this.props.selectedLocationId }
                isPaidCampaign={ this.props.isPaidCampaign }
                { ...footerProps }
            />);
        }
        return null;
    }

    handleBackRoute = () => {
        const { router } = this.props;

        if (this.isMxCl) {
            router.replace('/');
        }
        else if ([SITE_CODES.OLX_ID, SITE_CODES.OLX_AR, SITE_CODES.OLX_CO, SITE_CODES.OLX_PE, SITE_CODES.OLX_EC, SITE_CODES.OLX_IN].includes(this.siteCode)) {
            return;
        }
        else {
            router.goBack();
        }
    }

    handleIconClick = (evaluationType, sectionToRedirect) => {
        const { EVALUATION_STATES: { INITIAL, VALUATION_ERROR, APPOINTMENT_CREATED, VALUATION_DONE, CALLBACK_REQUESTED, VALUATION_STARTED }} = EvaluationConstants;
        let shouldCallLayout = false;
        const predictedPrice = UserSession.getEvaluationPredictedPrice() || {};
        const isPricePredicted = predictedPrice?.predictions?.list?.length;
        const type = evaluationType || UserSession.getUserEvaluationState();
        const backPath = this.isHandleSeoLink ? '/valuation-flow/sell' : '/valuation-flow';

        this.props.toggleShowAllCTA(true);

        if (type === VALUATION_ERROR) {
            UserSession.setUserEvaluationState(INITIAL);
            shouldCallLayout = true;
        }
        else if (type === VALUATION_DONE || type === APPOINTMENT_CREATED) {
            if (sectionToRedirect) {
                UserSession.setUserEvaluationState(VALUATION_STARTED);
                this.props.router.replace(backPath);
            }
            else {
                UserSession.setUserEvaluationState(INITIAL);
            }

            shouldCallLayout = true;
        }
        else if (type === CALLBACK_REQUESTED) {
            if (isPricePredicted) {
                UserSession.setUserEvaluationState(VALUATION_DONE);
                shouldCallLayout = true;
            }
            else {
                UserSession.setUserEvaluationState(VALUATION_STARTED);
                shouldCallLayout = true;
                if (!IS_DESKTOP) {
                    this.props.router.replace(backPath);
                    shouldCallLayout = false;
                }
            }
        }
        if (shouldCallLayout) {
            if (![SITE_CODES.OLX_ID, SITE_CODES.OLX_IN, SITE_CODES.OLX_AR, SITE_CODES.OLX_CO, SITE_CODES.OLX_PE, SITE_CODES.OLX_EC, SITE_CODES.OLX_TR].includes(this.siteCode) || !IS_DESKTOP) {
                this.handleBackRoute();
            }
            this.props.fetchCXELayout(this.props.tabName);
        }
    }

    handleCallbackClick = name => {
        const { EVALUATION_STATES: { CALLBACK_REQUESTED, INITIAL }} = EvaluationConstants;
        const isCallbackAssitedSIBtn = name === CALLBACK_ASSISTED_SI_HOME;
        const evaluationState = isCallbackAssitedSIBtn ? INITIAL : CALLBACK_REQUESTED;

        if (isCallbackAssitedSIBtn) {
            UserSession.deleteEvaluationFormValues();
            UserSession.deleteLastSelectionOption();
        }
        UserSession.setUserEvaluationState(evaluationState);
        this.props.fetchCXELayout(this.props.tabName);
        this.seller_state = CookieManager.readCookie(EvaluationConstants.SELLER_STATE_COOKIE);
        this.forceUpdate();

        if (UserSession.getUserEvaluationFormValues()) {
            const valuationUserValues = UserSession.getUserEvaluationFormValues();
            const leadId = this.props.localStorage.getItem(FIELD_CONSTANTS.LEAD_ID) || '';
            const isUserLoggedIn = Auth.isLoggedIn();

            const leadApiPayload = Object.keys(valuationUserValues)
                .filter(key => key.includes('-'))
                .reduce((prev, current) => {
                    const key = current.split('-')[0];

                    return {
                        ...prev,
                        [key]: valuationUserValues[current]
                    };
                }, {});

            leadApiPayload.leadId = leadId;
            leadApiPayload.status = CALLBACK_REQUESTED;
            leadApiPayload.name = leadApiPayload[FIELD_CONSTANTS.PREFERRED_NAME_KEY] || leadApiPayload[FIELD_CONSTANTS.NAME_KEY];

            if (isUserLoggedIn && !leadApiPayload[FIELD_CONSTANTS.PREFERRED_EMAIL_KEY] || !isUserLoggedIn) {
                leadApiPayload.email = leadApiPayload[FIELD_CONSTANTS.PREFERRED_EMAIL_KEY] || leadApiPayload[FIELD_CONSTANTS.EMAIL_KEY];
            }

            this.props.getLeadId(leadApiPayload);
        }
    }

    getCXELayout = () => {
        let layout = {};
        const { marketConfig } = this.props;

        const channel = CampaingHelpers.getChannelOfAcquisition(this.props.location.query, marketConfig?.get());
        const { CAMPAIGN_TYPE: { PAID }} = CampaignContants;

        if (this.isOlxAutosLandingPage) {
            const siteCode = marketConfig.get('siteCode');

            if (siteCode === SITE_CODES.OLX_CL || siteCode === SITE_CODES.OLX_MX || siteCode === SITE_CODES.OLX_TR) {
                layout = IS_DESKTOP ? LayoutConstants.defaultLayout_desktop_mx_cl : LayoutConstants.defaultLayout_mobile_mx_cl;
            }
            else {
                layout = IS_DESKTOP ? LayoutConstants[`defaultLayout_desktop_${ siteCode}`] : LayoutConstants[`defaultLayout_mobile_${ siteCode}`];
            }
            return layout;
        }
        layout = channel.type === PAID
            ? LayoutConstants.defaultLayout_PPC
            : LayoutConstants.defaultLayout_Organic;
        return layout;
    }

    onExchangeOverlayClose = () => {
        const { location } = this.props;
        const { pathname, query } = location;

        this.props.router.push({
            pathname,
            query,
            hash: ''
        });
    }

    /**
     * This function is temperory, it shows self inspection button
     * on valuation done and valuation error step for the PPC user.
     * Remove this function when this is handled from the cxe.
     */
    // Commenting out the this functionality as a part of ticket PAN-71541
    /* showSiButton = async () => {
        // Remove this check for PPC
        if (this.props.isPaidCampaign) {
            return;
        }

        if (this.olxAutosConfig && this.olxAutosConfig.citiesForSi) {
            if (this.props.isPaidCampaign) {
                const paidQueryParams = getSessionStorageItem('utmpk') ? JSON.parse(getSessionStorageItem('utmpk')) : undefined;

                const userCity = paidQueryParams?.campaignName?.split('|')[1];

                const isCityMatch
                    = !isEmpty(this.olxAutosConfig.citiesForSi.find(city => city.sphareName === userCity));

                let configToTakeForHidingSection = this.olxAutosConfig.inspectionCtaConfig.sectionToHideForNonMatchedCity;

                if (isCityMatch) {
                    configToTakeForHidingSection = this.olxAutosConfig.inspectionCtaConfig.sectionToHideForMatchedCity;
                }

                const omittedCxeSection = [
                    ...configToTakeForHidingSection
                ];

                this.setState({ omittedCxeSection });
            }
            else {
                let siLocation = this.props.siLocation;

                if (isEmpty(siLocation)) {
                    const siCity = this.props.localStorage.getItem('siCity');

                    siLocation = siCity ? JSON.parse(siCity) : undefined;
                }

                if (isEmpty(this.props.disclaimer)) {
                    await this.props.fetchDisclaimer();
                }

                const isCityMatch
                    = this.props.eligibleRegion?.includes(parseInt(siLocation?.sphareId, 10))
                    || this.props.eligibleRegion?.includes(siLocation?.sphareId?.toString());

                let configToTakeForHidingSection = this.olxAutosConfig.inspectionCtaConfig.sectionToHideForNonMatchedCity;

                if (isCityMatch) {
                    configToTakeForHidingSection = this.olxAutosConfig.inspectionCtaConfig.sectionToHideForMatchedCity;
                }

                const omittedCxeSection = [
                    ...configToTakeForHidingSection
                ];

                this.setState({ omittedCxeSection });
            }
        }
    }; */

    render() {
        let layout = {};
        const { TAB_NAME } = EvaluationConstants;
        const { tabName, experiments } = this.props;
        let hp_tracking_event = '';

        if (tabName === TAB_NAME.BUY) {
            hp_tracking_event = HP_OLX_TRACKING.HP_EXCHANGE_BUY;
        }
        else if (tabName === TAB_NAME.SELL) {
            hp_tracking_event = HP_OLX_TRACKING.HP_EXCHANGE_SELL;
        }

        if (this.props.isLoading) {
            return (
                <div data-aut-id="loader">
                    <LoadingPlaceholder />
                </div>
            );
        }

        if (this.props.isError) {
            layout = this.getCXELayout();
        }
        else {
            layout = this.props.layout || layout;
        }

        if (isEmpty(layout)) {
            return null;
        }
        const { metadata } = layout;
        const { header = {}, footer = {}, subHeader = {}, layoutStyle = {}} = metadata || {};
        const isAndroid = typeof window === 'undefined' ? false : /android/i.test(window.navigator.userAgent);
        const pageHeader = this.getPageHeader(header, this.props.tabName);
        const footerComponent = this.getFooterPage(footer);
        const subheader = this.getSubheader(subHeader);
        const categoryExtendedFooter = this.olxAutosConfig && this.olxAutosConfig.categoryExtendedFooter;
        const { location: { hash }} = this.props;
        const showExchangeOverlay = hash === this.exchangeOverlayHash;
        const lastSelectedSection = UserSession.getLastSelectionOption();
        const stickyWhatsappButtonVariant = experiments[STICKY_WHATSAPP_BUTTON_EXPERIMENT];
        const shouldShowStickyWhatsappButton = (['b', 'c'].includes(stickyWhatsappButtonVariant)) && (tabName === TAB_NAME.BUY || (tabName === TAB_NAME.SELL && (IS_DESKTOP || lastSelectedSection === 'make' || !lastSelectedSection)) || tabName === TAB_NAME.FINANCE);
        const isTradeInFFEnabled = this.props.marketConfig.get('enableTradeIn');
        const bannerTrue = (
            layout.name === B2C_DESKTOP_LAYOUT_NAME
            || layout.name === B2C_MOBILE_LAYOUT_NAME
            || layout.name === B2C_DESKTOP_NON_CMC_LAYOUT_NAME
            || layout.name === B2C_MOBILE_NON_CMC_LAYOUT_NAME
            || layout.name === C2B_DESKTOP_LAYOUT_NAME
            || layout.name === C2B_MOBILE_LAYOUT_NAME
            || layout.name === INSPECTION_DESKTOP_LAYOUT_NAME
            || layout.name === INSPECTION_MOBILE_LAYOUT_NAME);
        const images = bannerTrue == true ? (this.getImageItems(layout)) : [];

        const settings = {
            speed: 500,
            slidesToShow: 1,
            slidesToScroll: 1,
            nextArrow: <></>,
            prevArrow: <></>,
            autoplay: images?.length > 1, // Set to true to enable autoplay
            autoplaySpeed: 5000 // Set the autoplay speed in milliseconds (e.g., 3000ms for 3 seconds)
        };
        const renderBanner = () => {
            return (
                <section style={ IS_DESKTOP ? { height: 380, position: 'relative', paddingBottom: 0 } : { height: 275, position: 'relative', paddingBottom: 0 } }>
                    <SliderBanner
                        renderArrow={ (next, previous) => this.renderArrowAsset(layout, next, previous) }
                        settings={ settings }
                        images={ images }
                        getFileRestrictions={ this.getFileRestrictions(layout) }
                        isDesktop={ IS_DESKTOP }
                    />
                </section>
            );
        };

        return (
            <ComponentDependencyProvider value={ ComponentDependency } >
                <Page header={ pageHeader } footer={ footerComponent } subHeader={ subheader } fullWidth>
                    {this.renderSEOContent(this.transformDesktop(layout))}

                    <div className={ css.autos } style={ getHeaderHeight(header, subHeader, layoutStyle) } >
                        { isAndroid && this.siteCode === SITE_CODES.OLX_MX && <AppInstallation tabName={ tabName } sellerState={ this.seller_state } />}
                        {bannerTrue ? renderBanner() : <></> }
                        <OLXAutosHomePage
                            layout={ this.transformDesktop(layout) }
                            omittedCxeWidget={ this.state.omittedCxeWidget }
                            omittedCxeSection={ this.state.omittedCxeSection }
                            track={ this.trackWrapper }
                            location={ this.props.location }
                            locationId={ this.props.locationId }
                            userIsLogged={ this.props.userIsLogged }
                            isMxCl={ this.isOlxAutosLandingPage }
                            handleIconClick={ this.handleIconClick }
                            onItemView={ this.addImpressions }
                            handleCallbackClick={ this.handleCallbackClick }
                            disclaimer={ this.props.disclaimer }
                        />
                        { categoryExtendedFooter && !IS_DESKTOP
                            && tabName === TAB_NAME.BUY
                            && <BottomPopularSearchesMobile /> }
                        {shouldShowStickyWhatsappButton && <StickyWhatsappButton variant={ stickyWhatsappButtonVariant } />}
                    </div>
                    { showExchangeOverlay
                        && (isTradeInFFEnabled
                            ? (
                                <TradeInFormAsync
                                    selectFrom={ tabName }
                                />
                            )
                            : (
                                <UserDetailsFormAsync
                                    onClose={ this.onExchangeOverlayClose }
                                    userFlow={ EXPONEA_USER_FLOW.B2C }
                                    eventType={ EXPONEA_EVENT_TYPES.SWAP_SUBMIT }
                                    hp_tracking_event={ hp_tracking_event }
                                    eventOrigin="landingPage"
                                    track={ this.props.track }
                                    thankYouTitle={ this.props.intl.formatMessage({ id: 'thankYouVeryMuch' }) }
                                    thankYouSubtitle={ this.props.intl.formatMessage({ id: 'exchangeThankYouSubtitle2' }) }
                                />
                            )
                        )
                    }
                </Page>
            </ComponentDependencyProvider>
        );
    }
}

OLXAutosShell.fetchData = async (...args) => {
    const [dispatch, renderProps, reqProps] = args;

    const tabNameCookie = reqProps.cookies?.U_TAB;

    const apiVersion = FORM_FIELD_API_VERSION_L2Q;
    // const timestamp = Date.now();

    if ((!tabNameCookie || tabNameCookie === 'SELL')) {
        const query = renderProps?.location?.query;

        const promises = [];

        if (!isEmpty(query) && query.make) {
            // newrelic?.recordCustomEvent('OLXAutosShellFetchDataLogs', {
            //     message: 'Called On SSR',
            //     timestamp
            // });

            const valuationFormFields = await dispatch(fetchFormFields(apiVersion));

            // eslint-disable-next-line no-unused-vars
            const paramObj = {
                makeValue: query.make ? query.make : '',
                modelValue: query.model ? decodeURIComponent(query.model) : '',
                yearValue: query.year || '',
                variantValue: query.variant ? decodeURIComponent(query.variant) : '',
                mileageValue: query.mileage || ''
            };

            const carDetails = await dispatch(fetchCarDetails(paramObj));

            const valuationFormFieldsLocal = valuationFormFields?.data?.data || [];

            const carData = manipulateCarData({ valuationFormFields: valuationFormFieldsLocal }, (carDetails?.data || {}));

            const apiParam = carData?.lastSelectionOption || 'make';
            const userData = carData?.userData || {};

            const apiPathName = getAPIPathName(valuationFormFieldsLocal, apiParam);

            const userDataValue = await dispatch(getDataValues(apiPathName, userData));

            promises.push(valuationFormFields, carDetails, userDataValue);
        }
        else {
            promises.push(dispatch(fetchFormFields(apiVersion)), dispatch(fetchMakeValues()));
        }

        return Promise.all(promises);
    }
    return Promise.resolve();
};

const actionCreator = (dispatch, reqProps, { wrappedComponentPromise = Promise.resolve(), isSSR } = {}) => {
    // eslint-disable-next-line no-new
    new OLXAutosWrapper(); // QUICK FIX TO SET PLATFORM FOR AUTOS
    const layoutSource = getLayoutSource(reqProps);
    const seoInformation = SeoHelper.getSeoInformation(reqProps.params, reqProps.config);
    const params = getCXEQueryParams(reqProps, null, layoutSource, seoInformation);

    const laquesisCookie = reqProps?.cookies?.laquesis || '';
    const tabNameCookie = reqProps.cookies?.U_TAB;

    if ((!tabNameCookie || tabNameCookie === 'SELL') && isSSR) {
        dispatch(setAutosCurrentPageName(AUTOS_SELL_PAGE_NAME)); // SETTING THE PAGE NAME IN SSR TO DISABLE GOOGLE LOGIN IF USER LANDS DIRECTLY ON AUTOS PAGE
    }
    return wrappedComponentPromise.then(
        () => dispatch(fetchLayout({ ...params, layoutSource }, laquesisCookie)));
};

export const mapStateToProps = (state, ownProps) => {
    const queryString = ownProps && ownProps.location && ownProps.location.query;
    const isPaidCampaign = CampaingHelpers.isPaidCampaign(queryString, ownProps?.marketConfig?.get());
    const locationId = locationHelpers.getLocationId(state.locations.selectedLocation);
    const langs = ownProps?.marketConfig?.get('langs');
    const lang = langs && langs[0] && langs[0].smartlingLocaleCode;
    const tabName = state.cxeLayout.tabName;
    const layoutSource = getLayoutSource({ location: ownProps.location, config: ownProps.marketConfig.get(), params: ownProps.params });
    const siteCode = ownProps.marketConfig.get('siteCode');

    const selectorProps = {
        ...ownProps,
        locationId,
        layoutSource,
        lang,
        viaBFF: shouldRequestViaBFF({ tabName, layoutSource, siteCode, location: ownProps.location, config: ownProps.marketConfig.get() })
    };
    const { getLayoutSelector, isError, isFetching } = LayoutSelector;
    const { track: { origin = '', browsingMode = '' } = {}} = state;
    const selectedLocationId = state?.locations?.selectedLocation?.id ?? '';

    return {
        layout: getLayoutSelector(state, selectorProps),
        isLoading: isFetching(state, selectorProps),
        isError: isError(state, selectorProps),
        locationId: isPaidCampaign ? 'unknown' : locationId,
        lang,
        requestOrigin: getSessionStorageItem(REQUEST_ORIGIN),
        allCities: getCMCCities(state),
        userIsLogged: userIsLogged(state),
        landingURLSearch: state.track.landingURLSearch,
        tabName: state.cxeLayout.tabName,
        trackingOrigin: LayoutSelector.getTrackingOrigin(state, ownProps.location, ownProps.marketConfig),
        isPaidCampaign,
        origin,
        browsingMode,
        cxeBundle: state.cxeBundles,
        buyerIntentEvalState: state.buyerIntent && state.buyerIntent.evaluationState,
        selectedLocationId,
        siLocation: state.locations.siLocation,
        eligibleRegion: state.bookAppointment?.disclaimer?.data?.eligibleRegion?.filter?.locationsIds,
        disclaimer: state.bookAppointment?.disclaimer?.data,
        seoInformation: SeoHelper.getSeoInformation(ownProps.params, ownProps.marketConfig.get())
    };
};

export const mapDispatchToProps = (dispatch, ownProps) => ({
    setBrowsingMode: mode => dispatch(setBrowsingModeSync(mode)),
    setOrigin: origin => dispatch(setOriginSync(origin)),
    setURL: url => dispatch(setLandingURLSync(url)),
    fetchCXELayout: tabName => {
        const cookies = CookieManager.getAllCookies();
        const reqProps = { ...ownProps, config: ownProps.marketConfig.get() };
        const layoutSource = getLayoutSource(reqProps);
        const seoInformation = ownProps?.seoInformation;

        reqProps.cookies = cookies;

        dispatch(fetchLayout({ ...getCXEQueryParams(reqProps, tabName, layoutSource), layoutSource, seoInformation }));
    },
    setCurrentTab: (tabName, updateSession) => dispatch(setCurrentTab(tabName, updateSession)),
    toggleShowAllCTA: flag => dispatch(toggleShowAllCTA(flag)),
    fetchFiltersMetadata: ({ location, categoryId, locationId }) => dispatch(getFilterAbundanceMetadata({ categoryId, locationId, location, facetLimit: 100 })),
    fetchFiltersByCategoryId: (isAutosPlatform, categoryId) => dispatch(fetchFiltersByCategoryId(isAutosPlatform, categoryId)),
    updateBuyerIntentEvalState: evalState => dispatch(BuyerIntentActions.updateBuyerIntentEvalState(evalState)),
    _removeSiLocation: () => dispatch(removeSiLocation()),
    _setSiLocation: loc => dispatch(setSiLocation(loc)),
    getLeadId: (userDataValues, isOTPEnabled) => {
        const path = `${API_PATH.LEAD_API}?isOTPEnabled=${!!isOTPEnabled}`;

        return dispatch(post(path, 'CALLBACK_REQUESTED', userDataValues, PANAMERA_CLIENT));
    },
    fetchDisclaimer: () => dispatch(fetchDisclaimer())
});

export const getModule = ({ location, config, params }) => {
    if (config.get('SEO', 'opfModuleSupport')) {
        const landingPageRoute = config.get('landingPageRoutes');
        const { seoTheme } = params ?? {};

        if (seoTheme) {
            return 'sellThematicPage';
        }

        switch (location.pathname) {
            case '/':
                return 'homePage';
            case landingPageRoute['sell-organic']:
                return 'sellLandingPage';
            case landingPageRoute['buy-organic']:
                return 'buyLandingPage';
            case landingPageRoute['finance-organic']:
                return 'financeLandingPage';
            default:
                return 'homePage';
        }
    }
    return 'listings';
};

export default compose(
    withRouter,
    withCookies,
    withLocalStorage,
    withTrack,
    withDataFetching(actionCreator),
    withLocation,
    injectIntl,
    withConfig('marketConfig'),
    connect(mapStateToProps, mapDispatchToProps),
    withTags(getModule, routerParamsToSeoQuery),
    withSchema('WebSite'),
    withMultipleExperiments([STICKY_WHATSAPP_BUTTON_EXPERIMENT, ...Object.values(CLEARER_LISTING_PAGE_ENTRY_POINT_EXP), ...Object.values(OPC_EXP_QUOTE_PAGE), ...Object.values(SINGLE_PRICE_QUOTE_EXP)])
)(OLXAutosShell);
